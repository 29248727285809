:root {
    //color palette
    font-family: 'DM Sans Regular';
    --eduku-black-main: #0A0A0A;
    --eduku-black-light: #323232;
    --eduku-green-main: #00D196;
    --eduku-orange-main: #FF5C00;
    --eduku-grey-main: #676767;
    --eduku-grey-light: #F5F5F5;
    --eduku-silver-main: #A2A2A2;
}

body{
    margin: 0;
}


@font-face {
    font-family: 'DM Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Regular'), url('./assets/font/DMSans-Regular.woff') format('woff');
}


@font-face {
    font-family: 'DM Sans Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Italic'), url('./assets/font/DMSans-Italic.woff') format('woff');
}


@font-face {
    font-family: 'DM Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Medium'), url('./assets/font/DMSans-Medium.woff') format('woff');
}


@font-face {
    font-family: 'DM Sans Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Medium Italic'), url('./assets/font/DMSans-MediumItalic.woff') format('woff');
}


@font-face {
    font-family: 'DM Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Bold'), url('./assets/font/DMSans-Bold.woff') format('woff');
}


@font-face {
    font-family: 'DM Sans Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('DM Sans Bold Italic'), url('./assets/font/DMSans-BoldItalic.woff') format('woff');
}